<script setup>
import { onMounted, ref } from "vue";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";

defineProps({
    tabsInfo: {
        type: Array,
        default: () => {},
    },
});

const activeTab = ref(0);

onMounted(() => {
    setNavPills();
    setTooltip();
});

</script>
<template>
    <main>
        <div class="mx-auto mt-3 my-sm-auto ms-sm-auto me-sm-0">
            <div class="nav-wrapper position-relative end-0">
                <ul class="bg-transparent nav nav-pills nav-fill" role="tablist">
                    <li class="nav-item" v-for="(tab,index) in tabsInfo" :key="index">
                        <a class="px-0 py-1 mb-0 nav-link" :class="{ active: index === activeTab }" href="javascript:;" role="tab" 
                            aria-selected="true" :id="tab.value" data-bs-toggle="tab"
                            :data-bs-target="'#'+tab.value+'-pane'" type="button"
                            :aria-controls="tab.value+'-pane'" @click="activeTab = index">
                            <span class="ms-1">{{ tab.text }}</span>
                        </a>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div v-for="(tab, index) in tabsInfo" :key="tab.value" class="tab-pane fade"
                    :class="{ show: activeTab === index, active: activeTab === index }"
                     :id="tab.value+'-pane'" role="tabpanel"
                        :aria-labelledby="tab.value" tabindex="0">
                            <slot :name="tab.value"></slot>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
