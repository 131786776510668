<script setup>
import { ref, onMounted } from "vue";
import { getApplication } from "../services/services.js";
import { useRouter } from 'vue-router';
import { getDataFromLocalStorage } from '../utils/localStorageUtils';
import ApplicationCreate from "./ApplicationCreate.vue";
import DevicesList from "./DevicesList.vue";
import DownloadApplicationData from "./DownloadApplicationData.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonPopupButton from "@/components/ArgonPopupButton.vue";
import Tabs from "@/components/Tabs.vue";
import Spinner from "@/components/Spinner.vue";
import { deleteApplication } from "../services/services.js";

const application = ref(null)
const tenant = ref(null)
const is_admin = ref(false)
const permissions = ref(null)
const tabsInfo = ref([
    { text: "Dispositius", value: "devices" },
    { text: "Configuració", value: "config" },
    { text: "Descàrrega de Dades", value: "data" },
])
const router = useRouter();
const itemId = ref(router.currentRoute.value.params.id);
const deleteInput = ref(null)

const noDeleted = ref(false)

const deleteApp = ((app_id) => {
    noDeleted.value = false
    deleteApplication(app_id).then(response => {
        if(response.data === ''){
            router.push({ name: 'Aplicacions' });
        }else {
            noDeleted.value = true
        }
    })
})

onMounted(() => {
    tenant.value = getDataFromLocalStorage('tenantId')
    is_admin.value = getDataFromLocalStorage('account-data').is_admin
    let tenantData = getDataFromLocalStorage('account-data').tenants?.find(t => t.id == tenant.value.id)
    if (tenantData){
        permissions.value = tenantData.permissions  
    }
    getApplication(itemId.value).then(response => {
        application.value = response.data
    })

})

</script>
<template>
    <div class="container-fluid pt-4 pb-3 bg-white">
        <div class="row">
            <div class="col-lg-12">
                <div class="card" v-if="application">
                    <div class="card-header pb-0 p-3">
                        <div class="row">
                            <div class="col-6 d-flex align-items-center">
                                <h6>{{ application.name }} <span>
                                        <p><small> application id: {{ application.id }}</small></p>
                                    </span></h6>
                            </div>
                            <div class="col-6 text-end" v-if="is_admin">
                                <argon-popup-button button-text="Esborrar Aplicació"  color="danger" size="xs" variant="outline">
                                    <template #content>
                                        <div>
                                            <p class="mb-1 lh-sm text-xs">
                                                Escriu <strong>{{ application.name }}</strong> per confirmar que vols eliminar l'aplicació:
                                            </p>
                                            <argon-input id="deleteInput" class="mb-0" type="text" v-model="deleteInput" />
                                            <argon-button :disabled="deleteInput != application.name" color="success" size="xs"
                                                @click="deleteApp(application.id)">
                                                Esborrar
                                            </argon-button>
                                            <p v-if="noDeleted" class="text-warning text-xs mb-0 mt-1">No s'ha pogut esborrar l'aplicació prova-ho més tard.</p>
                                        </div>
                                    </template>
                                </argon-popup-button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body px-4 pt-0">
                        <tabs :tabs-info="tabsInfo">
                            <template #devices>
                                <devices-list :application="application" :is-admin="is_admin" :permissions="permissions" />
                            </template>
                            <template #config>
                                <application-create
                                    :form-create="false"
                                    :form-data="{ id:application.id ,name: application.name, description: application.description }" />
                            </template>
                            <template #data>
                                <download-application-data :appId="application.id"/>
                            </template>
                        </tabs>
                        <argon-button color="success" size="xs"
                            @click="router.push({ name: 'Aplicacions' });">
                            Tornar
                        </argon-button>
                    </div>
                </div>
                <spinner v-else />
            </div>
        </div>
    </div>
</template>
<style>

</style>