<script setup>
import { ref, onMounted, watch } from "vue";
import { useMetricsStore } from '../store/useMetricsStore.js'
import Spinner from "@/components/Spinner.vue";
import DataTable from "@/components/DataTable.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { useRouter } from 'vue-router';
import { getDataFromLocalStorage } from '../utils/localStorageUtils';

const gatewaysStore = useMetricsStore();
const gateways = ref([])
const hasElements = ref(false)
// const gatewaysCount = ref(0)
const tenant = ref(null)
const is_admin = ref(false)
const tableHeaders = ref([
    { text: "Estat", value: "state" },
    { text: "Last seen", value: "last_seen" },
    { text: "Nom", value: "name" },
    { text: "Id", value: "gateway_id" },
]);
const router = useRouter();

const paginate = (array, offset, limit) => {
    const start = offset;        // `offset` és la posició des d'on comencem a agafar elements
    const end = start + limit;   // `limit` és el nombre d'elements que volem obtenir
    return array.slice(start, end);  // Retorna els elements entre `start` i `end`
}

const refreshData = async (offset, rowsPerPage) => {
    gateways.value = paginate(gatewaysStore.items,offset,rowsPerPage)
    hasElements.value = true
    return gateways.value
};

const formatLastSeen = ((timestamp) => {
    if (timestamp) {
        const timestampInMilliseconds = timestamp.split('\n')[0].split(':')[1] * 1000 + Math.floor(timestamp.split('\n')[1].split(':')[1] / 1000000);
        const date = new Date(timestampInMilliseconds)
         // Extract components using local time methods
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // getMonth() returns 0-11
        const day = date.getDate();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();

        // Pad single digit minutes, seconds, and months with leading zeros
        const monthFormatted = month.toString().padStart(2, '0');
        const dayFormatted = day.toString().padStart(2, '0');
        const hoursFormatted = hours.toString().padStart(2, '0');
        const minutesFormatted = minutes.toString().padStart(2, '0');
        const secondsFormatted = seconds.toString().padStart(2, '0');

        // Construct the final string
        const formattedDate = `${year}-${monthFormatted}-${dayFormatted} ${hoursFormatted}:${minutesFormatted}:${secondsFormatted}`;
        return formattedDate

    } else return 'Never'
})

watch(gatewaysStore, () => {
    hasElements.value = false
    setTimeout(() => {
        refreshData(0,10)
    },500)
},{deep:true})

onMounted(() => {
    hasElements.value = false
    refreshData(0,10)
    tenant.value = getDataFromLocalStorage('tenantId')
    is_admin.value = getDataFromLocalStorage('account-data').is_admin
})

</script>
<template>
    <div class="container-fluid pt-4 pb-3 bg-white">
        <div class="row">
            <div class="col-lg-12">
                <div class="card" style="min-height: 750px;">
                    <div class="card-header" :class="is_admin ? 'border-bottom p-3' : 'p-0'">
                        <div class="row">
                            <div class="col-6 d-flex align-items-center">
                            </div>
                            <div class="col-6 text-end" v-if="is_admin">
                                <argon-button color="success" size="sm" variant="outline" @click="router.push({ path: '/gateways/create' })">Afegeix una Gateway</argon-button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body px-4 py-3" v-if="hasElements">
                        <div class="table-responsive p-0">
                            <DataTable class="table" :refreshData="refreshData"
                                :total-count="gatewaysStore.items.length" :tableHeaders="tableHeaders" :tableItems="gateways">
                                <template #item-state="tableItem">
                                    <div v-if="tableItem.state == 1">
                                        <div class="state online"></div>Online
                                    </div>
                                    <div v-else>
                                        <div class="state offline"></div>Offline
                                    </div>
                                </template>
                                <template #item-gateway_id="tableItem">
                                    <a role="button" class="nav-link" @click="router.push({ path: '/gateways/'+ tableItem.gateway_id })">{{ tableItem.gateway_id }}</a>
                                </template>
                                <template #item-last_seen="tableItem">
                                    {{ formatLastSeen(tableItem.last_seen) }}
                                </template>
                            </DataTable>
                        </div>
                    </div>
                    <spinner class="pt-5" v-else />
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.state {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    float: left;
    margin-top: 4px;
    margin-right: 5px;
}
.online{
    background-color: green;
}
.offline{
    background-color: red;
}
</style>
