<!--
=========================================================
* Vue Argon Dashboard 2 - v4.0.0
=========================================================

* Product Page: https://creative-tim.com/product/vue-argon-dashboard
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
-->
<script setup>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import Sidenav from "./views/components/Sidenav";
import Navbar from "./views/components/Navbar.vue";
import { useMetricsStore } from './store/useMetricsStore.js'

const store = useStore();
const isNavFixed = computed(() => store.state.isNavFixed);
const darkMode = computed(() => store.state.darkMode);
const isAbsolute = computed(() => store.state.isAbsolute);
const showSidenav = computed(() => store.state.showSidenav);
const layout = computed(() => store.state.layout);
const showNavbar = computed(() => store.state.showNavbar);

const navClasses = computed(() => {
    return {
        "position-sticky bg-white left-auto top-2 z-index-sticky":
            isNavFixed.value && !darkMode.value,
        "position-sticky bg-default left-auto top-2 z-index-sticky":
            isNavFixed.value && darkMode.value,
        "position-absolute px-4 mx-0 w-100 z-index-2": isAbsolute.value,
        "": !isAbsolute.value,
    };
});

onMounted(async () => {
    const gateways = useMetricsStore();
    await gateways.fetchData();
})

</script>
<template>
    <div v-show="layout === 'landing'" class="landing-bg h-100 bg-gradient-primary position-fixed w-100"></div>
    <sidenav v-if="showSidenav" />
    <main class="main-content position-relative max-height-vh-100 border-radius-lg">
        <navbar :class="[navClasses]" v-if="showNavbar" />
        <router-view />
    </main>
</template>
