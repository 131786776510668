<script setup>
import { onMounted, ref } from 'vue';
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonCheckbox from "@/components/ArgonCheckbox";
import ArgonTextarea from "@/components/ArgonTextarea.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonLoadingButton from "@/components/ArgonLoadingButton.vue";
import Toast from "@/components/Toast.vue";
import { useRouter } from 'vue-router';
import { getDeviceProfiles, postDevice } from "../services/services.js";
import { getDataFromLocalStorage } from '../utils/localStorageUtils';

const props = defineProps({
    formData: { type: Object, default: () => { } },
    formCreate: { type: Boolean, default: true },
})

const formData = {
    dev_eui: props.formData && props.formData.dev_eui ? props.formData.dev_eui : '',
    name: props.formData && props.formData.name ? props.formData.name : '',
    description: props.formData && props.formData.description ? props.formData.description : '',
    device_profile_id: props.formData && props.formData.device_profile_id ? props.formData.device_profile_id : '',
    skip_fcnt_check: props.formData && props.formData.skip_fcnt_check ? props.formData.skip_fcnt_check : false,
    is_disabled: props.formData && props.formData.is_disabled ? props.formData.is_disabled : false,
    variables: props.formData && props.formData.variables ? props.formData.variables : '',
    tags: props.formData && props.formData.tags ? props.formData.tags : {},
    join_eui: props.formData && props.formData.join_eui ? props.formData.join_eui : '',
    OTAA: props.formData && props.formData.OTAA ? props.formData.OTAA : '',
};

const tags = ref(Object.entries(formData.tags).map(([key, value]) => ({ key, value })));

const router = useRouter();
const errors = ref([])
const deviceProfiles = ref([])
const appId = ref(router.currentRoute.value.params.appId)
const is_admin = ref(false)
const permissions = ref(null)
const is_loading = ref(false)
const toastType = ref('success')
const alertText = ref("")

const showAlert = ((text, type = 'success') => {
    toastType.value = type
    alertText.value = text
})

const addDevice = (() => {
    is_loading.value = true
    let hasError = false
    errors.value = []
    if (!formData.name) {
        errors.value['name'] = true
        hasError = true
    }
    if (!formData.dev_eui) {
        errors.value['dev_eui'] = true
        hasError = true
    }
    if (!formData.device_profile_id) {
        errors.value['device_profile_id'] = true
        hasError = true
    }
    if (!formData.OTAA) {
        errors.value['OTAA'] = true
        hasError = true
    }

    if (hasError){
        is_loading.value = false
        return
    }
    formData.tags = tags.value.reduce((acc, obj) => {
        acc[obj.key] = obj.value;
        return acc;
    }, {})
    postDevice(appId.value,formData, props.formCreate).then(response => {
        is_loading.value = false
        if(response.status != 200){
            showAlert("Hi ha hagut un error: "+response.data, 'error')
        }else{
            showAlert("S'ha afegit el dispositiu correctament")
            setTimeout(() => {
                router.push({ name: 'Detall Aplicació', params: { id: appId.value } });
            }, 2000)
        }
    })
})

const addTag = (() => {
    tags.value.push({
        key: '',
        value: null
    })
})

const removeTag = (index) => {
  tags.value.splice(index, 1);
};

const back = (() => {
    router.push({ name: 'Detall Aplicació', params: { id: appId.value } });
})

onMounted(() => {
    getDeviceProfiles().then(response => {
        deviceProfiles.value = response.data
    })
    is_admin.value = getDataFromLocalStorage('account-data').is_admin
    let tenantData = getDataFromLocalStorage('account-data').tenants?.find(t => t.id == getDataFromLocalStorage('tenantId').id)
    if (tenantData){
        permissions.value = tenantData.permissions  
    }
})


</script>
<template>
    <div class="container-fluid p-0 pb-3 bg-white">
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header pb-2 p-3 px-4">
                        <div class="row">
                            <div class="col-6 d-flex align-items-center">
                                <h6><span v-if="formCreate">Afegir</span><span v-else>Actualitzar</span> un Dispositiu</h6>
                            </div>
                        </div>
                    </div>
                    <div class="card-body px-4 pt-0 pb-2">
                        <form role="form">
                            <div class="row">
                                <div class="col-md-12">
                                    <label for="name"><span class="obligatory">*</span>Nom</label>
                                    <argon-input id="name" :disabled="!is_admin && permissions?.is_device_admin == 'False'" isRequired type="text" :error="errors['name']"
                                        v-model="formData.name" required aria-label="Name" />
                                </div>
                                <div class="col-md-12">
                                    <argon-textarea :disabled="!is_admin && permissions?.is_device_admin == 'False'" id="description" type="text" aria-label="Description"
                                        v-model="formData.description">
                                        Descripció
                                    </argon-textarea>
                                </div>
                                <div class="col-md-6">
                                    <label for="dev_eui"><span class="obligatory">*</span>Device EUI (EUI64)</label>
                                    <argon-input id="dev_eui" :disabled="!formCreate || (!is_admin && permissions?.is_device_admin == 'False')" isRequired type="text" :error="errors['dev_eui']"
                                        v-model="formData.dev_eui" required aria-label="dev_eui" />
                                </div>
                                <div class="col-md-6">
                                    <label for="OTAA"><span class="obligatory">*</span>OTAA Key</label>
                                    <argon-input id="OTAA" :disabled="!is_admin && permissions?.is_device_admin == 'False'" isRequired type="text" :error="errors['OTAA']"
                                        v-model="formData.OTAA" required aria-label="OTAA" />
                                </div>
                                <div class="col-md-12">
                                    <label for="dev_profile"><span class="obligatory">*</span>Device profile</label>
                                    <select class="select-items form-control m-auto mb-3" :disabled="!is_admin && permissions?.is_device_admin == 'False'" :class="errors['device_profile_id'] ? 'hasError': ''" v-model="formData.device_profile_id">
                                        <option v-for="deviceProfile, index in deviceProfiles" :key="index" :value="deviceProfile.id">
                                            {{ deviceProfile.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <argon-checkbox v-model="formData.is_disabled" :disabled="!is_admin && permissions?.is_device_admin == 'False'">
                                        <label for="checkDeviceDisabled">
                                            Device is disabled
                                            <br>
                                            <small><font-awesome-icon icon="circle-info" class="mt-2 text-sm opacity-10" />
                                                No activar els dispositius fins que no estiguin instal·lats a la ubicació final
                                            </small>
                                        </label>
                                    </argon-checkbox>
                                </div>
                                <div class="col-md-6">
                                    <argon-checkbox v-model="formData.skip_fcnt_check" :disabled="!is_admin && permissions?.is_device_admin == 'False'">
                                        <label for="checkDisableFrameCounter">
                                            Disable frame-counter validation
                                        </label>
                                    </argon-checkbox>
                                </div>
                                <div class="col-md-12">
                                    <label for="tag">Tags</label>
                                    <div class="border p-3" style="border-radius: 10px;">
                                        <div class="row" v-for="(tag, index) in tags" :key="index">
                                            <div class="col-md-3">
                                                <argon-input :id="'tag_index_'+tag.key" :disabled="!is_admin && permissions?.is_device_admin == 'False'" type="text"
                                                    v-model="tags[index].key" aria-label="Value" />
                                            </div>
                                            <div class="col-md-8">
                                                <argon-input :id="'tag_value_'+tag.key" :disabled="!is_admin && permissions?.is_device_admin == 'False'" type="text"
                                                    v-model="tags[index].value" aria-label="Value" />
                                            </div>
                                            <div class="col-md-1">
                                                <argon-button color="white" :disabled="!is_admin && permissions?.is_device_admin == 'False'" variant="outline" type="button" class="mb-2 text-warning p-2" @click="removeTag(index)">
                                                    <font-awesome-icon icon="trash" class="text-warning text-sm opacity-10" />
                                                </argon-button>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <argon-button color="success" :disabled="!is_admin && permissions?.is_device_admin == 'False'" size="xs" variant="outline" type="button" class="text-xs" @click="addTag">
                                                    <font-awesome-icon icon="plus" class="text-success text-xs opacity-10" />
                                                </argon-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p><small class="obligatory">* Dades obligatòries</small></p>
                            <div class="">
                                <argon-button v-if="formCreate" size="xs" color="success" type="button" class="my-4 mb-2"
                                    @click="back">
                                    <span>Tornar</span>
                                </argon-button>
                                <argon-loading-button :is-loading="is_loading"  
                                    :disabled="is_loading || (!is_admin && permissions?.is_device_admin == 'False')" color="success" type="button" 
                                    icon="upload"
                                    class="my-4 mb-2 float-end" size="xs" variant="dark" @click="addDevice">
                                    <span v-if="formCreate">Afegir</span>
                                    <span v-else>Actualitzar</span>
                                </argon-loading-button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <toast :alertText="alertText" :toast-type="toastType"></toast>
</template>
<style>
select.hasError {
    border-color: #fd5c70;
}
</style>