<script setup>
import { ref, onMounted, watch } from "vue";
import { useMetricsStore } from '../store/useMetricsStore.js'
import { getDataFromLocalStorage } from '../utils/localStorageUtils';
import { useRouter } from 'vue-router';
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonPopupButton from "@/components/ArgonPopupButton.vue";
import GatewayCreate from "./GatewayCreate.vue";
import Map from "@/components/Maps.vue";
import Tabs from "@/components/Tabs.vue";
import gatewayIcon from "@/assets/img/emptyGateway.png";
import EChartComponent from '@/components/EChart.vue';
import Spinner from "@/components/Spinner.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import { deleteGateway } from "../services/services.js";

const gateways = useMetricsStore();
const gateway = ref(null)
const tenant = ref(null)
const is_admin = ref(false)
const router = useRouter();
const itemId = ref(router.currentRoute.value.params.id);
const showMap = ref(false)
const gatewaysMarkers = ref([])
const noDeleted = ref(false)
const deleteInput = ref(null)
const tabsInfo = ref([
    { text: "Detall", value: "detail" },
    { text: "Configuració", value: "config" },
])

const metricsLoaded = ref(false)
let chartColors = null

const basicMetrics = ref({
    height: "150px",
    options: {
        xAxis: {
            type: 'category',
            data: [],
            axisLabel: {}
        },
        legend:{
            show: true,
            top:'bottom'
        },
        grid:{
            top: 30,
            bottom: 50,
            left: 57,
        },
        yAxis: {
            name: "",
            type: 'value',
            position: 'left',
        },
        tooltip: {
            show: true,
        },
        series: [],
    }
})

const rx_packets = ref(JSON.parse(JSON.stringify(basicMetrics.value)))
const tx_packets = ref(JSON.parse(JSON.stringify(basicMetrics.value)))
const tx_packets_per_status = ref(JSON.parse(JSON.stringify(basicMetrics.value)))

const deleteGat = (() => {
    noDeleted.value = false
    deleteGateway(gateway.value.gateway_id).then(response => {
        if (response.data === '') {
            gateways.fetchData();
            gateways.removeItemById(gateway.value.gateway_id)
            router.push({ name: 'Gateways' });
        } else {
            noDeleted.value = true
        }
    })
})

const getMonthDay = (value) => {
    const months = ["Gen", "Feb", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Oct", "Nov", "Des"];
    const [day, month] = value.split("/");
    const monthName = months[parseInt(month) - 1];
    return `${monthName} ${parseInt(day)}`;
}

const initGatway = () => {
    gateway.value = gateways.getGatewayById(itemId.value)
    if(!gateway.value) return 

    if(gateway.value.tags?.color){
        chartColors = gateway.value.tags?.color
    }
    if (gateway.value.location) {
        gatewaysMarkers.value.push({
            lat: gateway.value.location.lat,
            lng: gateway.value.location.long,
            icon: gatewayIcon,
            color: chartColors,
            content: `<p><small>` + gateway.value.name + `<br>` + gateway.value.gateway_id + `</small></p>`,
        })
    }
    
    showMap.value = true
    rx_packets.value.options.xAxis.data = gateway.value.metrics.rx_packets.timestamps.map(t => new Date(t*1000).toLocaleDateString())
    rx_packets.value.options.xAxis.axisLabel =  {
        formatter: function (value) {
            return getMonthDay(value)
        }
    }
    rx_packets.value.options.yAxis.name = "Paquets rebuts"
    rx_packets.value.options.legend.show = false
    rx_packets.value.options.series = [{
        type: 'line',
        name: 'Paquets rebuts',
        data: gateway.value.metrics.rx_packets.count,
        itemStyle: {
            color: gateway.value.tags['color'] ?? '#404a85'
        },
    }]
    
    tx_packets.value.options.xAxis.data = gateway.value.metrics.tx_packets.timestamps.map(t => new Date(t*1000).toLocaleDateString())
    tx_packets.value.options.xAxis.axisLabel =  {
        formatter: function (value) {
            return getMonthDay(value)
        }
    }
    tx_packets.value.options.yAxis.name = "Paquets transmesos"
    tx_packets.value.options.legend.show = false
    tx_packets.value.options.series = [{
        type: 'line',
        name: 'Paquets transmesos',
        data: gateway.value.metrics.tx_packets.count,
        itemStyle: {
            color: gateway.value.tags['color'] ?? '#404a85'
        },
    }]
    
    tx_packets_per_status.value.options.xAxis.data = gateway.value.metrics.tx_packets_per_status.timestamps.map(t => new Date(t*1000).toLocaleDateString())
    tx_packets_per_status.value.options.xAxis.axisLabel =  {
        formatter: function (value) {
            return getMonthDay(value)
        }
    }
    tx_packets_per_status.value.options.yAxis.name = "Paquets tx / estat"
    gateway.value.metrics.tx_packets_per_status.count.forEach((status) => {
        tx_packets_per_status.value.options.series.push({
            type: 'bar',
            name: status.name,
            stack: 'a',
            data: status.data,
            itemStyle: {},
        })
    })
    if (tx_packets_per_status.value.options.series.find(s => s.name == 'OK')){
        tx_packets_per_status.value.options.series.find(s => s.name == 'OK').itemStyle = {color: gateway.value.tags['color']}
    }
    metricsLoaded.value = true
}

watch(gateways, () => {
    initGatway()
}, { deep: true })

onMounted(() => {
    tenant.value = getDataFromLocalStorage('tenantId')
    is_admin.value = getDataFromLocalStorage('account-data').is_admin
    initGatway()
})

</script>
<template>
    <div class="container-fluid pt-4 pb-3 bg-white">
        <div class="row">
            <div class="col-lg-12">
                <div class="card" v-if="gateway">
                    <div class="card-header pb-0 p-3">
                        <div class="row">
                            <div class="col-6 d-flex align-items-center">
                                <h6>{{ gateway.name }} <span>
                                        <p><small> gateway id: {{ gateway.gateway_id }}</small></p>
                                    </span></h6>
                            </div>
                            <div class="col-6 text-end" v-if="is_admin">
                                <argon-popup-button button-text="Esborrar Gateway" color="danger" size="xs"
                                    variant="outline">
                                    <template #content>
                                        <div>
                                            <p class="mb-1 lh-sm text-xs">
                                                Escriu <strong>{{ gateway.name }}</strong> per confirmar que vols eliminar la gateway:
                                            </p>
                                            <argon-input id="deleteInput" class="mb-0" type="text" v-model="deleteInput" />
                                            <argon-button :disabled="deleteInput != gateway.name" color="success" size="xs" class="mt-2"
                                                @click="deleteGat(gateway.gateway_id)">
                                                Esborrar
                                            </argon-button>
                                            <p v-if="noDeleted" class="text-warning text-xs mb-0 mt-1">No s'ha pogut esborrar la gateway prova-ho més tard.</p>
                                        </div>
                                    </template>
                                </argon-popup-button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body px-4 pt-0 pb-3">
                        <tabs :tabs-info="tabsInfo">
                            <template #detail>
                                <div class="container-fluid py-4">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="card">
                                                <div class="card-body p-0 rounded">
                                                    <Map v-if="showMap" id="gatewayMap" class="mb-3 rounded"
                                                        :markers="gatewaysMarkers"
                                                        style="width: 100%; height: 550px; z-index: 1">
                                                    </Map>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="row" v-if="metricsLoaded">
                                                <div class="col-12" style="height: 185px;">
                                                    <EChartComponent v-if="rx_packets.options?.series?.length > 0" :options="rx_packets.options" class="h-100">
                                                    </EChartComponent>
                                                </div>
                                                <div class="col-12" style="height: 185px;">
                                                    <EChartComponent v-if="tx_packets.options?.series?.length > 0" :options="tx_packets.options" class="h-100">
                                                    </EChartComponent>
                                                </div>
                                                <div class="col-12" style="height: 185px;">
                                                    <EChartComponent v-if="tx_packets_per_status.options?.series?.length > 0" :options="tx_packets_per_status.options" class="h-100">
                                                    </EChartComponent>
                                                </div>
                                            </div>
                                            <spinner v-else />
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template #config>
                                <gateway-create :form-create="false" :form-data="{
                                    gateway_id: gateway.gateway_id,
                                    name: gateway.name,
                                    description: gateway.description,
                                    stats_interval: gateway.stats_interval,
                                    tags: gateway.tags
                                }" />
                            </template>
                        </tabs>
                        <argon-button color="success" size="xs"
                            @click="router.push({ name: 'Gateways' });">
                            Tornar
                        </argon-button>
                    </div>
                </div>
                <spinner v-else />
            </div>
        </div>
    </div>
</template>
<style></style>