<script setup>
import { ref, onMounted, computed } from "vue";
import { useRouter } from 'vue-router';
import { postActiveDevices, getAllAppDevices, postDeleteDevices } from "../services/services.js";
// import SelectableDataTable from "@/components/SelectableDataTable.vue";
import dataTableMuliple from "@/components/DataTableMultipleSelection.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonLoadingButton from "@/components/ArgonLoadingButton.vue";
import ArgonPopupButton from "@/components/ArgonPopupButton.vue";
import setTooltip from "@/assets/js/tooltip.js";
import Toast from "@/components/Toast.vue";

const props = defineProps({
    application: { type: Object, default: () => { } },
    isAdmin: { type: Boolean, default: false },
    permissions: { type: Object, default: null },
})

const router = useRouter();
const devices = ref([])
const tableHeaders = ref([
    { text: "Last seen", value: "last_seen_at", sortable: true },
    { text: "Nom", value: "name", sortable: true },
    { text: "DevEUI", value: "dev_eui", sortable: true },
    { text: "Activat", value: "is_disabled", sortable: true },
    { text: "Tags", value: "tags", sortable: true },
]);

const filters = ref({
    text: '',
})

const is_loading = ref(false)
const is_loading_delete = ref(false)
const toastType = ref('success')
const alertText = ref("")

const showAlert = ((text, type = 'success') => {
    toastType.value = type
    alertText.value = text
})

const itemsSelectedInComponent = ref([])
const updateItemsSelected = (newValue) => {
    itemsSelectedInComponent.value = newValue;
};

const filteredDevices = computed(() => {
    return devices.value.filter(item => {
        const textMatch = item.dev_eui?.toString().toLowerCase().includes(filters.value.text.toLowerCase()) 
                            || item.name.toLowerCase().toLowerCase().includes(filters.value.text.toLowerCase());
        return textMatch;
    });
})

// const refreshData = async (offset, rowsPerPage, searchText = '') => {
//     // search.value = ''
//     itemsSelectedInComponent.value = []
//     return await getAppDevices(props.application.id, offset, rowsPerPage, searchText).then(response => {
//         devices.value = response.data
//         return devices.value
//     })
// };

const activeDevices = (() => {
    is_loading.value = true
    postActiveDevices(props.application.id, itemsSelectedInComponent.value).then(response => {
        if(response.status != 200){
            showAlert("Hi ha hagut un error: "+response.data, 'error')
        }else{
            showAlert("Els dispositius s'han activat correctament")
            setTimeout(() => {
                window.location.reload()
            }, 1300)
        }
        is_loading.value = false
    })
})

const deleteDevices = (() => {
    is_loading_delete.value = true
    postDeleteDevices(props.application.id, itemsSelectedInComponent.value).then(response => {
        if(response.status != 200){
            showAlert("Hi ha hagut un error: "+response.data, 'error')
        }else{
            showAlert("Els dispositius s'han eliminat correctament")
            setTimeout(() => {
                window.location.reload()
            }, 1300)
        }
        is_loading_delete.value = false
    })
})

const formatLastSeen = ((timestamp) => {
    if (timestamp) {
        // const timestampInMilliseconds = timestamp.split('\n')[0].split(':')[1] * 1000 + Math.floor(timestamp.split('\n')[1].split(':')[1] / 1000000);
        const date = new Date(timestamp)
         // Extract components using local time methods
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // getMonth() returns 0-11
        const day = date.getDate();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();

        // Pad single digit minutes, seconds, and months with leading zeros
        const monthFormatted = month.toString().padStart(2, '0');
        const dayFormatted = day.toString().padStart(2, '0');
        const hoursFormatted = hours.toString().padStart(2, '0');
        const minutesFormatted = minutes.toString().padStart(2, '0');
        const secondsFormatted = seconds.toString().padStart(2, '0');

        // Construct the final string
        const formattedDate = `${year}-${monthFormatted}-${dayFormatted} ${hoursFormatted}:${minutesFormatted}:${secondsFormatted}`;
        return formattedDate

    } else return 'Never'
})

onMounted(() => {
    getAllAppDevices(props.application.id).then(response => {
        devices.value = response.data
    })
    // refreshData()
    setTooltip();
})

</script>

<template>
    <div class="container-fluid p-0 pt-4 bg-white">
        <div class="row">
            <div class="col-lg-12">
                <div class="">
                    <div class="card-header pb-2 p-3 px-4">
                        <div class="row">
                            <div class="col-6 d-flex align-items-center">
                                <h6 style="margin-top:-105px">Llista de dispositius</h6>
                            </div>
                            <div class="col-6 text-end">
                                <div class="text-end" v-if="isAdmin || permissions.is_device_admin == 'True'">
                                    <argon-button color="success" size="sm" variant="outline" class="mb-2" style="z-index: 100;"
                                        @click="router.push({ path: '/applications/'+ props.application.id+'/device/create' })">Afegeix dispositius
                                    </argon-button>
                                    <span v-if="itemsSelectedInComponent.length < 1" class="tool-tip text-xs mb-2 d-block" 
                                        data-bs-toggle="tooltip" data-bs-placement="right" 
                                        :title="itemsSelectedInComponent.length < 1 ? 'Selecciona mínim un dispositiu per activar': 'Activar dispositius'">
                                        <argon-loading-button icon="circle-check" color="success" size="sm" variant="outline" class="d-inline" style="z-index: 100;"
                                            :disabled="itemsSelectedInComponent.length < 1" @click="activeDevices">
                                            Activar dispositius
                                        </argon-loading-button>
                                    </span>
                                    <div v-else>
                                        <argon-loading-button icon="circle-check" :is-loading="is_loading" color="success" size="sm" variant="outline" class=" mb-2" style="z-index: 100;"
                                            :disabled="itemsSelectedInComponent.length < 1" @click="activeDevices">
                                            Activar dispositius
                                        </argon-loading-button>
                                    </div>
                                    <span v-if="itemsSelectedInComponent.length < 1" class="tool-tip text-xs" 
                                        data-bs-toggle="tooltip" data-bs-placement="right" 
                                        :title="itemsSelectedInComponent.length < 1 ? 'Selecciona mínim un dispositiu per esborrar': 'Esborrar dispositius'">
                                        <argon-loading-button color="success" size="sm" variant="outline" class="d-inline mb-3" style="z-index: 100; padding-right: 28px; padding-left: 28px;"
                                            :disabled="itemsSelectedInComponent.length < 1" @click="deleteDevices">
                                            Esborrar dispositius
                                        </argon-loading-button>
                                    </span>
                                    <argon-popup-button v-else button-text="Esborrar dispositius"  color="success" size="sm" variant="outline">
                                        <template #content>
                                            <div>
                                                <p class="mb-1 lh-sm text-xs">
                                                    Segur que vols eliminar <span v-if="itemsSelectedInComponent.length == 1">el dispositiu seleccionat?</span> <span v-else>els {{ itemsSelectedInComponent.length }} dispositius seleccionats?</span> 
                                                </p>
                                                <argon-loading-button icon="circle-check" :is-loading="is_loading_delete" color="success" size="sm" variant="outline" class="d-block" style="z-index: 100;"
                                                    :disabled="itemsSelectedInComponent.length < 1" @click="deleteDevices">
                                                    Esborrar dispositius
                                                </argon-loading-button>
                                            </div>
                                        </template>
                                    </argon-popup-button>
                                </div>
                            </div>
                        </div>
                        <argon-input id="search" type="text" style="margin-top: -5px" placeholder="Cerca per nom" v-model="filters.text" aria-label="search" class="w-25"/>
                    </div>
                    <div class="card-body px-4 pt-0 pb-2">
                        <div class="table-responsive p-0 d-inline">
                            <dataTableMuliple class="table" :tableHeaders="tableHeaders" :tableItems="filteredDevices"
                                :itemsSelected="itemsSelectedInComponent" @update:itemsSelected="updateItemsSelected" empty-message="No hi ha variacions per enviar">
                                <template #item-last_seen_at="tableItem">
                                    {{ formatLastSeen(tableItem.last_seen_at) }}
                                </template>
                                <template #item-dev_eui="tableItem">
                                    <a role="button" class="nav-link" @click="router.push({ path: '/applications/'+ props.application.id+'/device/'+ tableItem.dev_eui })">{{ tableItem.dev_eui }}</a>
                                </template>
                                <template #item-is_disabled="tableItem">
                                    <font-awesome-icon v-if="!tableItem.is_disabled" icon="circle-check" class="icon" />
                                    <font-awesome-icon v-else icon="ban" class="icon" />
                                </template>
                            </dataTableMuliple>
                            <!-- <selectable-data-table :searchable="true" class="table" :refreshData="refreshData"
                                :total-count="props.application.devices" :tableHeaders="tableHeaders" :itemsSelected="itemsSelectedInComponent"
                                @update:itemsSelected="updateItemsSelected" :tableItems="devices">
                                <template #item-last_seen_at="tableItem">
                                    {{ formatLastSeen(tableItem.last_seen_at) }}
                                </template>
                                <template #item-dev_eui="tableItem">
                                    <a role="button" class="nav-link" @click="router.push({ path: '/applications/'+ props.application.id+'/device/'+ tableItem.dev_eui })">{{ tableItem.dev_eui }}</a>
                                </template>
                            </selectable-data-table> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <toast :alertText="alertText" :toast-type="toastType"></toast>
</template>