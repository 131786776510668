<script setup>
import { ref, onMounted } from "vue";
import ApplicationsList from "./components/ApplicationsList.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { getDataFromLocalStorage } from '../utils/localStorageUtils';
import { getApplications } from "../services/services.js";
import { useRouter } from 'vue-router';

const applications = ref([])
const tenant = ref(null)
const is_admin = ref(false)
const router = useRouter();
const selectDetail = ((id) => {
    router.push({ name: 'Detall Aplicació', params: { id: id } });
})

onMounted(() => {
    tenant.value = getDataFromLocalStorage('tenantId')
    is_admin.value = getDataFromLocalStorage('account-data').is_admin
    getApplications().then(response => {
        response.data.forEach(element => {
            applications.value.push({
                icon: {
                    component: 'ni ni-app',
                    background: 'dark',
                },
                label: element.name,
                description: element.id,
            })
        });
    })

})
</script>
<template>
    <div class="container-fluid pt-4 bg-white">
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header" :class="is_admin ? 'border-bottom p-3' : 'p-0'">
                        <div class="row">
                            <div class="col-6 d-flex align-items-center">
                            </div>
                            <div class="col-6 text-end" v-if="is_admin">
                                <argon-button color="success" size="sm" variant="outline" @click="router.push({ path: '/applications/create' })">Afegeix una Aplicació</argon-button>
                            </div>
                        </div>
                    </div>
                    <applications-list :categories="applications" @select-detail="selectDetail"/>
                </div>
            </div>
        </div>
    </div>
</template>
