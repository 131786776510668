import moment from "moment/moment";

export function formatDDMMYYYY(date) {
    return moment(date).format('DD/MM/YYYY')
}

export function formatDDMMYYYYHHmm(date) {
    return moment(date).format('DD/MM/YYYY HH:mm')
}

export function formatDDMMHHmm(date) {
    return moment(date).format('DD/MM HH:mm')
}

export function formatDDMMYYHHmm(date) {
    return moment(date).format('YYYY-MM-DD HH:mm')
}

export function formatDDMMYYHHmmZone(date) {
    const momentDate = moment(date);
    const offset = momentDate.utcOffset(); // Get the original offset

    return momentDate.add(offset, 'minutes').format('YYYY-MM-DD HH:mm');
}

export function getAllDatesBetween(startDate, endDate) {
    const days = [];
    let currentDate = new Date(startDate);
    while (currentDate <= new Date(endDate)) {
        days.push(formatDDMMYYYY(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return days;

}

export function formatLiteralDate(date_text) {
    const date = new Date(date_text);
    const year = date.getUTCFullYear(); // Get year
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Get month, convert to 2 digits
    const day = date.getUTCDate().toString().padStart(2, '0'); // Get day, convert to 2 digits
    const hours = date.getUTCHours().toString().padStart(2, '0'); // Get hours, convert to 2 digits
    const minutes = date.getUTCMinutes().toString().padStart(2, '0'); // Get minutes, convert to 2 digits
    const seconds = "00"; // Set seconds to '00'

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}