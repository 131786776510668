<script setup>
import { onMounted, ref } from 'vue';
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonTextarea from "@/components/ArgonTextarea.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import Toast from "@/components/Toast.vue";
import { useRouter } from 'vue-router';
import { postApplication } from "../services/services.js";
import { getDataFromLocalStorage } from '../utils/localStorageUtils';

const props = defineProps({
    formData: { type: Object, default: () => { } },
    formCreate: { type: Boolean, default: true },
})

const formData = {
    id: props.formData && props.formData.id ? props.formData.id : null,
    name: props.formData && props.formData.name ? props.formData.name : '',
    description: props.formData && props.formData.description ? props.formData.description : ''
};

const router = useRouter();
const errors = ref([])
const is_admin = ref(false)

const toastType = ref('success')
const alertText = ref("")

const showAlert = ((text, type = 'success') => {
    toastType.value = type
    alertText.value = text
})

const addApp = (() => {
    if(!formData.name){
        errors.value['name'] = true
        return
    }
    postApplication(formData, props.formCreate).then(response => {
        if(response.status != 200){
            showAlert("Hi ha hagut un error: "+response.data, 'error')
        }else{
            if(props.formCreate){
                showAlert("L'aplicació s'ha creat correctament")
            }else{
                showAlert("L'aplicació s'ha actualitzat correctament")
            }
            setTimeout(() => {
                if(props.formCreate){
                    router.push({ name: 'Detall Aplicació', params: { id: response.data } });
                }else{
                    window.location.reload()
                }
            }, 2000)
        }
    })
})

onMounted (() => {
    is_admin.value = getDataFromLocalStorage('account-data').is_admin
})

</script>
<template>
    <div class="container-fluid p-0 pt-4 bg-white">
        <div class="row">
            <div class="col-lg-12">
                <div class="">
                    <div class="card-header pb-2 p-3 px-4">
                        <div class="row" v-if="is_admin">
                            <div class="col-6 d-flex align-items-center">
                                <h6 v-if="props.formCreate">Afegir una Aplicació</h6>
                                <h6 v-else>Actualitzar una Aplicació</h6>
                            </div>
                            <div class="col-6 text-end">
                            </div>
                        </div>
                    </div>
                    <div class="card-body px-4 pt-0 pb-2">
                        <form role="form">
                            <label for="name"><span class="obligatory">*</span> Nom</label>
                            <argon-input id="name" isRequired type="text" :disabled="!is_admin" :error="errors['name']" v-model="formData.name" required aria-label="Name" />
                            <argon-textarea id="description" type="text" :disabled="!is_admin"
                                aria-label="Description" v-model="formData.description">Descripció</argon-textarea>
                            <p><small class="obligatory">* Dades obligatòries</small></p>
                            <div class="">
                                <argon-button v-if="props.formCreate" size="xs" color="success" variant="dark" type="button" class="my-4 mb-2" @click="router.push({ name: 'Aplicacions' });">
                                    Tornar
                                </argon-button>
                                <argon-button color="success" size="xs" variant="dark" type="button" :disabled="!is_admin" class=" float-end my-4 mb-2" @click="addApp">
                                    <span v-if="props.formCreate">Afegir</span>
                                    <span v-else>Actualitzar</span>
                                </argon-button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <toast :alertText="alertText" :toast-type="toastType"></toast>
</template>
