<script setup>
import { ref, onMounted } from 'vue';
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { useRouter } from 'vue-router';
import { getDeviceProfiles, postDevices } from "../services/services.js";
import { Download, JsonToCsv } from "../utils/utils.js"
import ArgonLoadingButton from "@/components/ArgonLoadingButton.vue";
import Toast from "@/components/Toast.vue";

const router = useRouter();
const errors = ref([])
const appId = ref(router.currentRoute.value.params.appId)
const file = ref(null)
const deviceProfiles = ref([])
const deviceProfileSelected = ref(null)
const responseText = ref('')
const is_loading = ref(false)
const toastType = ref('success')
const alertText = ref("")

const showAlert = ((text, type = 'success') => {
    toastType.value = type
    alertText.value = text
    file.value = null
    deviceProfileSelected.value = null
})

const handleFileChange = ((event) => {
    const fileEvent = event.target.files[0]; // Get the first selected file
    if (fileEvent) {
        file.value = fileEvent
    } else {
        file.value = null
    }
})

const addDevices = (() => {
    is_loading.value = true
    errors.value = []
    responseText.value = ''
    let hasErrors = false
    if (!file.value) {
        errors.value['file'] = true
        hasErrors = true
    }
    if (!deviceProfileSelected.value) {
        errors.value['device_profile_id'] = true
        hasErrors = true
    }
    if(hasErrors){
        is_loading.value = false
        return
    }
    const formData = new FormData();
    formData.append('file', file.value, file.value.name);
    formData.append('dp_id', deviceProfileSelected.value);
    postDevices(appId.value, formData).then(response => {
        if(response.status == 403){
            showAlert(response.data, 'error')
            is_loading.value = false
            return
        }
        if(response.data.ko.length > 0){
            Download(JsonToCsv(response.data.ko),  'errors_importacio.csv', 'text/csv;charset=utf-8;')
        }
        is_loading.value = false
        showAlert("S'han inserit un total de "+response.data.ok+" devices. Hi ha hagut error a un total de "+response.data.ko.length+" devices.")
    })
})

onMounted(() => {
    getDeviceProfiles().then(response => {
        deviceProfiles.value = response.data
    })
})

</script>
<template>
    <div class="container-fluid p-0 bg-white">
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header pb-2 p-3 px-4">
                        <div class="row">
                            <div class="col-6 d-flex align-items-center">
                                <h6>Afegir varis Dispositius</h6>
                            </div>
                            <div class="col-6 text-end">
                            </div>
                        </div>
                    </div>
                    <div class="card-body px-4 pt-0 pb-2">
                        <form role="form">
                            <div class="row">
                                <div class="col-md-6">
                                    <label for="app"><span class="obligatory"></span>ID Aplicació</label>
                                    <argon-input id="app" isRequired type="text" disabled v-model="appId"
                                         required aria-label="File" />
                                </div>
                                <div class="col-md-6">
                                    <label for="dev_profile"><span class="obligatory">*</span>Device profile</label>
                                    <select class="select-items form-control m-auto mb-3" :class="errors['device_profile_id'] ? 'hasError': ''" v-model="deviceProfileSelected">
                                        <option v-for="deviceProfile, index in deviceProfiles" :key="index" :value="deviceProfile.id">
                                            {{ deviceProfile.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <label for="name"><span class="obligatory">*</span>Nom</label>
                                    <argon-input id="name" isRequired type="file" :error="errors['file']"
                                        @change="handleFileChange" required aria-label="File" />
                                </div>
                            </div>
                            <p><small class="obligatory">* Dades obligatòries</small></p>
                            <p><small>El fitxer ha de tenir les següents claus:<br>· MeterSerialNumber (Nom del comptador)<br>· DevEUI <br>· AppKey (OTAA)<br><span style="font-style: italic; font-size: 11px;">Camp opcional: MeterDescription (Descripció el comptador)</span></small></p>
                            <p v-if="responseText" class="text-center">{{ responseText }}</p>
                            <div class="">
                                <argon-button color="success" size="xs" type="button" class="my-4 mb-2"
                                    @click="router.push({ name: 'Detall Aplicació', params: { id: appId } });">
                                    <span>Tornar</span>
                                </argon-button>
                                <argon-loading-button :is-loading="is_loading"  
                                    :disabled="is_loading" color="success" type="button" 
                                    icon="upload"
                                    class="my-4 mb-2 float-end" variant="dark" size="xs" @click="addDevices">
                                    <span>Carregar dispositius</span>
                                </argon-loading-button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <toast :alertText="alertText" :toast-type="toastType"></toast>
</template>
<style>
select.hasError {
    border-color: #fd5c70;
}
</style>