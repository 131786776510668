<script setup>
import { ref, onMounted, watch } from "vue";
import MiniStatisticsCard from "./components/MiniStatisticsCard.vue";
import Map from "@/components/Maps.vue";
import gatewayIcon from "@/assets/img/emptyGateway.png";
import gatewayErrorIcon from "@/assets/img/emptyGatewayError.png";
import EChartComponent from '@/components/EChart.vue';
import Spinner from "@/components/Spinner.vue";
import { getGatewaysCount, getApplications, getDevicesCount } from "../services/services";
import { useMetricsStore } from '../store/useMetricsStore.js'
import { getAllDatesBetween } from '@/utils/dateUtils.js'

const gatewaysCount = ref(0)
const gatewaysStore = ref(useMetricsStore())
const gateways = ref([])
const applications = ref([])
const devices = ref(0)
const activeDevices = ref(0)
const gatewaysMarkers = ref([])
const showMap = ref(false)
const chartEvents = ref({
    height: "300px",
    options: { 
        xAxis: {
            type: 'category',
            data: []
        },
        grid: {
            top: '5%',
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        yAxis: {
            name: "Paquets tx",
            type: 'value',
            position: 'left',
        },
        tooltip: {
            show: true,
        },
        series: [],
    }
})
const chartEventsLoading = ref(false)
const chartEventsLoaded = ref(false)

const getMonthDay = (value) => {
    const months = ["Gen", "Feb", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Oct", "Nov", "Des"];
    const [day, month] = value.split("/");
    const monthName = months[parseInt(month) - 1];
    return `${monthName} ${parseInt(day)}`;
}

const createChart = () => {
    let today = new Date()
    let monthAgo = new Date()
    monthAgo.setMonth(monthAgo.getMonth() - 1)
    let dates = getAllDatesBetween(monthAgo.toDateString(), today.toDateString())
    let series = []
    gateways.value.forEach(gateway => {
        series.push({
            type: 'bar',
            stack: 'a',
            name: gateway.name,
            data: gateway.metrics.tx_packets.count,
            itemStyle: {
                color: gateway.tags['color']
            },
        })
    })
    chartEvents.value.options.xAxis.data = dates
    chartEvents.value.options.xAxis.axisLabel =  {
        formatter: function (value) {
            return getMonthDay(value)
        }
    }
    chartEvents.value.options.series = series
       
    chartEventsLoading.value = false
    chartEventsLoaded.value = true
}

const addMarkers = () => {
    gateways.value.forEach(gateway => {
        gatewaysMarkers.value.push({
            lat: gateway.location?.lat,
            lng: gateway.location?.long,
            icon: gateway.state == '1' ? gatewayIcon : gatewayErrorIcon,
            content: `<p><small>`+gateway.name+`<br>`+gateway.gateway_id+`</small></p>`,
            color: gateway.tags['color'] || 'green',
        })
    })
    showMap.value = true
}

watch(gatewaysStore, () => {
    gateways.value = gatewaysStore.value.items
    addMarkers()
    createChart()
}, { deep: true })

onMounted(async() => {
    chartEventsLoading.value = true
    gateways.value = gatewaysStore.value.items;
    getGatewaysCount().then(response => {
        gatewaysCount.value = response.data
    })
    if(gateways.value.length > 0){
        addMarkers()
        createChart()
    }
    getDevicesCount().then(response => {
        devices.value = response.data.all
        activeDevices.value = response.data.active
    })
    getApplications().then(response => {
        applications.value = response.data
    })
})
</script>
<template>
    <div class="py-4 container-fluid bg-white">
        <div class="row">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-3">
                        <div class="row">
                            <div class="col">
                                <mini-statistics-card title="Gateways" 
                                    :value="gateways.filter(g => g.state == '1').length+'/'+gatewaysCount" 
                                    description="Gateways actives" 
                                    :icon="{
                                        component: 'wifi',
                                        background: 'bg-success',
                                        shape: 'rounded-circle',
                                }" />
                            </div>
                            
                        </div>
                        <div class="row">
                            <div class="col">
                                <mini-statistics-card title="Aplicacions" :value="applications.length" description="" :icon="{
                                        component: 'border-none',
                                        background: 'bg-success',
                                        shape: 'rounded-circle',
                                    }" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <mini-statistics-card title="Dispositius Actius" :value="activeDevices+'/'+devices" description="" :icon="{
                                    component: 'check',
                                    background: 'bg-success',
                                    shape: 'rounded-circle',
                                }" />
                            </div>
                        </div>
                        
                    </div>
                    <div class="col-9">
                        <div class="card overflow-auto p-3" style="height: 337px;">
                            <div v-if="!chartEventsLoading">
                                <p v-if="chartEventsLoaded && chartEvents.options.series.length > 0"
                                    class="mb-0 text-sm text-uppercase text-center">
                                    Número de paquets transmesos l'últim mes
                                </p>
                                <EChartComponent style="max-height: 282px;" v-if="chartEventsLoaded && chartEvents.options.series.length > 0" 
                                    :options="chartEvents.options">
                                </EChartComponent>
                                <div v-else><p class="text-center mt-5">No hi ha dades de trames</p></div>
                            </div>
                            <spinner v-else />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="card overflow-auto mt-1" style="min-height: calc(100vh - 483px);">
                            <Map v-if="showMap" id="gatewayMap" class="mb-3" :markers="gatewaysMarkers"
                                style="width: 100%; height: calc(100vh - 483px); z-index: 1">
                            </Map>
                            <spinner class="mt-3" v-else />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
