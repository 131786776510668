import { defineStore } from 'pinia';
import { getGateways, getGatewaysCount } from '../services/services.js'
import { getDataFromLocalStorage } from '../utils/localStorageUtils'

export const useMetricsStore = defineStore('metrics', {
    state: () => ({
        items: []  // Emmagatzemarem l'array d'objectes aquí
    }),
    getters: {
        getGatewayById: (state) => {
            return (gatewayId) => state.items.find((g) => g.gateway_id === gatewayId)
        },
    },
    actions: {
        removeItemById(itemId) {
            this.items = this.items.filter(item => item.gateway_id !== itemId);
        },
        async fetchData() {
            try {
                if(getDataFromLocalStorage('account-data').token){ 
                    getGatewaysCount().then(async response => {
                        const responseG = await getGateways(0, response.data, true)
                        this.items = responseG.data; // Guardem la resposta a l'estat
                        return true
                    })
                }
            } catch (error) {
                console.error('Error al recuperar les dades:', error);
            }
        }
    }
})