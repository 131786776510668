<script setup>
import { onMounted, ref } from "vue";
import mqtt from "mqtt";
import DeviceCreate from "./DeviceCreate.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonPopupButton from "@/components/ArgonPopupButton.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import Tabs from "@/components/Tabs.vue";
import Spinner from "@/components/Spinner.vue";
import { useRouter } from 'vue-router';
import { getDevice, getDeviceEvents } from "../services/services";
import { getDataFromLocalStorage } from '../utils/localStorageUtils';
import { formatDDMMYYYYHHmm } from '../utils/dateUtils';
import { deleteDevice } from "../services/services.js";

const router = useRouter();
const device = ref(null)
const is_admin = ref(false)
const noDeleted = ref(false)
const deleteInput = ref(null)
const appId = ref(router.currentRoute.value.params.appId)
const events = ref([])

const tabsInfo = ref([
    { text: "Configuració", value: "config" },
    { text: "Events", value: "detail" },
])

const back = (() => {
    router.push({ name: 'Detall Aplicació', params: { id: appId.value } });
})

const deleteDev = ((dev_eui) => {
    noDeleted.value = false
    deleteDevice(dev_eui).then(response => {
        if(response.data === ''){
            router.push({ name: 'Detall Aplicació', params: { id: appId.value } });
        }else {
            noDeleted.value = true
        }
    })
})

onMounted(() => {
    events.value = []
    is_admin.value = getDataFromLocalStorage('account-data').is_admin
    getDevice(router.currentRoute.value.params.id).then(response => {
        device.value = response.data
        getDeviceEvents(device.value.dev_eui).then(response => {
            response.data.events.sort(function(a, b) {
                var keyA = new Date(a.time),
                    keyB = new Date(b.time);
                // Compare the 2 dates
                if (keyA < keyB) return 1;
                if (keyA > keyB) return -1;
                return 0;
            });
            events.value = response.data.events.slice(0,10)
            const client  = mqtt.connect(process.env.VUE_APP_EVENTS_WS_IP,{
                clean: true,
            })
            client.on('connect', function () {
                // Subscribe to a topic
                client.subscribe(`application/${appId.value}/device/${device.value.dev_eui}/event/+`, function (err) {
                    if (!err) {
                        // Publish a message to a topic
                        client.publish('test', 'Hello mqtt')
                    }else {
                        console.log('error',err)
                    }
                })
            })
        
            // Receive messages
            client.on('message', function (topic, message) {
                // message is Buffer
                let m = JSON.parse(message.toString())
                m['event'] = topic.split('/')[5]
                if (m['event'] == 'up' || m['event'] == 'join'){
                    events.value.unshift(m)
                }
                client.end()
            })
        
            client.on('error', function (error) {
                console.log('error',error)
            })
        })
    })
})
</script>


<template>
    <div class="container-fluid pt-4 pb-3 bg-white">
        <div class="row">
            <div class="col-lg-12">
                <div class="card" v-if="device">
                    <div class="card-header pb-0 p-3">
                        <div class="row">
                            <div class="col-6 d-flex align-items-center">
                                <h6>Dispositiu {{ device.name }}</h6>
                            </div>
                            <div class="col-6 text-end">
                                <argon-popup-button v-if="is_admin" button-text="Esborrar Dispositiu"  color="danger" size="xs" variant="outline">
                                    <template #content>
                                        <div>
                                            <p class="mb-1 lh-sm text-xs">
                                                Escriu <strong>{{ device.name }}</strong> per confirmar que vols eliminar el dispositiu:
                                            </p>
                                            <argon-input id="deleteInput" class="mb-0" type="text" v-model="deleteInput" />
                                            <argon-button :disabled="deleteInput != device.name" color="success" size="xs" class="mt-2"
                                                @click="deleteDev(device.dev_eui)">
                                                Esborrar
                                            </argon-button>
                                            <p v-if="noDeleted" class="text-warning text-xs mb-0 mt-1">No s'ha pogut esborrar el dispositiu prova-ho més tard.</p>
                                        </div>
                                    </template>
                                </argon-popup-button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body px-4 pt-0 pb-3">
                        <tabs :tabs-info="tabsInfo">
                            <template #detail>
                                <div class="container-fluid p-0 pt-4 pb-3 bg-white">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="card">
                                                <div class="card-header pb-2 p-3 px-4">
                                                    <div class="row">
                                                        <div class="col-6 d-flex align-items-center">
                                                            <h6><span class="float-start me-3">Events</span><spinner size="xs" class="d-flex ms-3 mt-1"/></h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-body px-4 pt-0 pb-2">
                                                    <table class="table table-striped table-hover">
                                                        <tbody>
                                                            <tr class="table_contents" v-for="event in events" :key="event">
                                                                <td class="align-middle" style="width: 100px;">{{ formatDDMMYYYYHHmm(event.time) }}</td>
                                                                <td class="align-middle text-center" style="width: 100px;"><span class="badge bg-success">{{ event.event }}</span></td>
                                                                <td v-if="event.event == 'up'">
                                                                    <span><strong>Dr: </strong>{{ event.dr }}</span><br>
                                                                    <span class="truncate"><strong>Data: </strong>{{ event.data }}</span><br>
                                                                    <span><strong>FCnt: </strong>{{ event.f_cnt ?? event.fCnt }}</span>
                                                                    <span class="ms-3"><strong>FPort: </strong>{{ event.f_port ?? event.fPort }}</span>
                                                                </td>
                                                                <td v-else>
                                                                    <span>DevAddr: {{ event.dev_addr }}</span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template #config>
                                <device-create v-if="device"
                                    :form-create="false"
                                    :form-data="{ 
                                        dev_eui: device.dev_eui,
                                        name: device.name,
                                        description: device.description,
                                        device_profile_id: device.device_profile_id,
                                        skip_fcnt_check: device.skip_fcnt_check ?? false,
                                        is_disabled: device.is_disabled ?? false,
                                        variables: device.variables,
                                        tags: device.tags,
                                        join_eui: device.join_eui,
                                        OTAA: device.OTAA }"
                                    />   
                            </template>
                        </tabs>
                        <argon-button color="success" type="button" size="xs" class="my-4 mb-2"
                            @click="back">
                            <span>Tornar</span>
                        </argon-button>
                    </div>
                </div>
                <spinner v-else />
            </div>
        </div>
    </div>
</template>
<style>
.table_contents{
    font-size: 12px;
}
.truncate{
    white-space: nowrap;        /* Evita que el text es trenqui en múltiples línies */
    overflow: hidden;           /* Oculta qualsevol text que sobrepassi el límit del contenidor */
    text-overflow: ellipsis;    /* Mostra els punts suspensius quan el text és massa llarg */
    width: 500px;
    display: inline-block;
    margin-bottom: -6px;
}
</style>