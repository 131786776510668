<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const isRTL = computed(() => store.state.isRTL);
const emit = defineEmits(["selectDetail"]);

defineProps({
    title: {
        type: String,
        default: "Aplicacions",
    },
    categories: {
        type: Array,
        required: true,
        icon: {
            component: String,
            background: String,
        },
        label: String,
        description: String,
    },
});

</script>
<template>
    <div class="card-body p-0 pt-3 mb-3">
        <ul id="applications_list" class="p-0">
            <li v-for="(
            { icon: {  }, label, description }, index
          ) of categories" :key="index" :class="`mb-2 border-0 list-group-item d-flex justify-content-between`" type="button" @click="emit('selectDetail', description)">
                <div class="d-flex align-items-center">
                    <div class="d-flex flex-column">
                        <h6 class="mb-1 text-sm text-dark text-decoration-underline">{{ label }}</h6>
                        <span class="text-xs" v-html="description"> </span>
                    </div>
                </div>
                <div class="d-flex">
                    <button class="my-auto btn btn-link btn-icon-only btn-rounded btn-sm text-dark icon-move-right"
                        @click="emit('selectDetail', description)">
                        <i :class="`ni ${isRTL ? 'ni-bold-left' : 'ni-bold-right'}`" aria-hidden="true"></i>
                    </button>
                </div>
            </li>
        </ul>
    </div>

</template>
<style>
#applications_list li:hover{
    background-color: #c8d4e8;
}
</style>